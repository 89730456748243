<template>
  <div class="successful_message">
    <img
      :src="$asset('/images/forms/success_check@2x.png')"
      class="success_graphic"
      alt="Welcome to Hypefactors. Its gonna be hypetastic"
      width="150"
    >

    <h3>{{ $t('pages.register.high_five') }}</h3>

    <p v-if="!journalist" class="m-b-m">
      {{ $t('pages.register.confirmation_sent') }}
    </p>

    <p v-if="journalist" class="m-b-m">
      {{ $t('pages.register.confirmation_sent_journalists') }}
    </p>

    <p>
      {{ $t('pages.register.if_you_have_questions') }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    journalist: {
      type: Boolean,
      default: false
    }
  }
}
</script>
