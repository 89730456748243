<template>
  <transition name="fade">
    <div v-if="serverErrors" class="error-msg notification is-danger">
      <ul>
        <li v-for="error in serverErrors" :key="error.source">
          <span>
            {{ error.detail || error }}
          </span>
        </li>
      </ul>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    serverErrors: {
      type: Array,
      default: null
    }
  }
}
</script>
