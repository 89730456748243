import { mapGetters } from 'vuex'
import { RegisterApiService } from '@hypefactors/shared/js/services/api/RegisterApiService'

/**
 * A register newsroom base that holds all the common code between all the register pages.
 * @module RegisterBase
 */
export default {
  data () {
    return {
      serverErrors: null,
      successful: false,
      isLoading: false,

      formFields: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        ip_address: '',
        type: 'company',
        country_iso2: ''
      },

      registerUrl: 'users'
    }
  },

  computed: {
    ...mapGetters(['findCountryByIsoAlpha']),
    gaEventName: () => 'User sign up from page'
  },

  mounted () {
    this.prefill()
    this.getUsersIP()
  },

  methods: {
    prefill () {
      const query = this.$route.query
      this.formFields.email = query.email || ''
    },

    getUsersIP () {
      return RegisterApiService.getUserIpAndCountry()
        .then(({ ip, country }) => {
          this.formFields.ip_address = ip
          this.formFields.country_iso2 = country
        })
        .catch(error => console.log(error))
    },

    submitRegister () {
      let vm = this

      vm.$v.$touch()

      if (this.isLoading) {
        return false
      }

      if (vm.$v.$error) {
        this.$ma.trackEvent({
          category: 'Users',
          action: this.gaEventName,
          label: 'Invalid Fields'
        })

        return false
      }

      this.isLoading = true
      this.registerRequest()
    },

    registerRequest () {
      let vm = this
      const countyIso = this.formFields.country_iso2
      let currency = ''

      if (countyIso) {
        const country = this.findCountryByIsoAlpha(this.formFields.country_iso2)
        currency = this.$safeGet(country, 'currency.data.code', '')
      }

      this.$api.post(this.registerUrl, {
        ...vm.formFields,
        currency
      })
        .then(response => {
          // send Google Analytics event
          this.isLoading = false
          this.$notify.success(this.$t('success.successfully_created_user'))

          this.$ma.trackEvent({
            category: 'Users',
            action: this.gaEventName,
            label: 'Successful'
          })

          vm.successful = true

          this.onRegisterSuccess(response)
        })
        .catch(error => {
          this.isLoading = false
          this.$displayRequestError(error, this.$t('errors.user_could_not_be_registered'))
          vm.serverErrors = this.$safeGet(error, 'response.data.errors', [])
        })
    },

    onRegisterSuccess (response) {
      const userId = response.data.data.id

      this.$redirectTo(this.$getUrl(`/onboarding/${userId}`, 'hypefactors'), 5000)
    },

    sendGAClickEvent (event) {
      this.$ma.trackEvent({
        category: 'button',
        action: 'click',
        label: event
      })
    }
  }
}
